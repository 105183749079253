import React, { Component, ComponentClass, StatelessComponent } from 'react';

import { getEventLogger } from '..';

// This screen is intentionally made with as few dependencies as possible

export type ErrorBoundaryProps = {
  onError?: (error?: any) => void;
  isFullscreen?: boolean;
  /** Text to display when there's an error */
  text?: string;
  /** Custom component to display when there's an error. */
  FallbackComponent?: React.ComponentType<DefaultErrorFallbackProps>;
  _cypressForceProductionMode?: boolean;
  _cypressForceDevelopmentMode?: boolean;
};

const containerStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  width: '100%',
  padding: '24px',
  overflow: 'auto',
};

const fullscreenStyles: React.CSSProperties = {
  position: 'fixed',
  height: '100vh',
  left: 0,
  top: 0,
};

type DefaultErrorFallbackProps = {
  error: any;
  stack: string;
  text?: string;
  isFullscreen?: boolean;
};

const DefaultErrorFallback: React.StatelessComponent<DefaultErrorFallbackProps> = ({ text, isFullscreen }) => {
  return (
    <div
      style={{
        ...containerStyles,
        ...(isFullscreen ? fullscreenStyles : {}),
      }}
    >
      <style>{`#cloud-icon:before { content: '\\f21b'; }`}</style>
      <i
        id="cloud-icon"
        style={{
          display: 'inline-block',
          font: "normal normal normal 14px/1 'Material-Design-Iconic-Font'",
          fontSize: '54px',
          textRendering: 'auto',
        }}
      />
      <p>{text || `Sorry, we were unable to load the requested content`}</p>
    </div>
  );
};

export default class ErrorBoundary extends Component<ErrorBoundaryProps, { caughtError: any }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      caughtError: null,
    };
  }

  componentDidCatch(error: any) {
    this.setState({ caughtError: error || true });
    // TODO: pass the second arg (errorInfo) to include the component stack (needs changes in zen-js)
    if (typeof this.props.onError === 'function') {
      this.props.onError(error);
    }
    getEventLogger().logError(error);
  }

  render() {
    let isDevelopment = __DEVELOPMENT__;
    if (this.props._cypressForceDevelopmentMode) {
      isDevelopment = true;
    } else if (this.props._cypressForceProductionMode) {
      isDevelopment = false;
    }

    const { caughtError } = this.state;
    if (caughtError) {
      const stack = (caughtError as Error).stack || '';

      const FallbackComponent = this.props.FallbackComponent || DefaultErrorFallback;
      return isDevelopment ? (
        <div>
          <h2>Exception happened, check the browser console.</h2>
          <div>{(caughtError as Error).message || caughtError}</div>
          <div style={{ color: 'red' }}>
            {stack.split(/\n/).map((line: string, i: number) => (
              /* eslint-disable react/no-array-index-key */
              <div key={i} style={{ paddingLeft: i > 0 ? '20px' : '' }}>
                {line}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <FallbackComponent
          error={caughtError}
          stack={stack}
          text={this.props.text}
          isFullscreen={this.props.isFullscreen}
        />
      );
    }

    return this.props.children;
  }
}

export function withErrorBoundary<OwnProps>(params: ErrorBoundaryProps = {}) {
  return function withErrorBoundary<WrappedComponentProps>(WrappedComponent: ComponentClass | StatelessComponent) {
    class WithErrorBoundary extends Component<OwnProps> {
      static displayName = `WithErrorBoundary(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

      render() {
        return (
          <ErrorBoundary {...params}>
            <WrappedComponent {...this.props} />
          </ErrorBoundary>
        );
      }
    }

    return WithErrorBoundary;
  };
}
