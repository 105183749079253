import React, { useEffect, useState, ComponentType, FunctionComponent } from 'react';

import { SupportFlowPages, SupportFlowPageProps } from './types';
import IssueSelectPage from './IssueSelectPage';
import SupportRequestPage from './SupportRequestPage';
import SupportRecommendations from '../components/SupportRecommendations';
import WelcomeHub from './WelcomeHub';
import SolvvyWindow, { SolvvyVersion } from '../SolvvyWindow';
import RequestReceived from '../components/RequestRecieved';
import OpenCases from '../components/OpenCases';

const supportPages: { [key: string]: ComponentType<SupportFlowPageProps> } = {
  [SupportFlowPages.issueSelect]: IssueSelectPage,
  [SupportFlowPages.recommendedMethods]: SupportRecommendations,
  [SupportFlowPages.submitRequest]: SupportRequestPage,
  [SupportFlowPages.requestReceived]: RequestReceived,
  [SupportFlowPages.openCases]: OpenCases,
  [SupportFlowPages.welcomeHub]: WelcomeHub,
};

export type SupportFlowProps = {
  initialPage?: keyof typeof SupportFlowPages;
  onClose: () => void;
  containerHeight?: number;
  show?: boolean;
  firstStepBack?: () => void;
  solvvyVersion: SolvvyVersion;
  isContractorLite?: boolean;
  isSolvvyKillSwitchEnabled?: boolean;
};

const SupportFlow: FunctionComponent<SupportFlowProps> = ({
  initialPage,
  onClose,
  containerHeight,
  show,
  firstStepBack,
  solvvyVersion,
  isContractorLite,
  isSolvvyKillSwitchEnabled,
}) => {
  const newPage =
    isContractorLite || !isSolvvyKillSwitchEnabled ? SupportFlowPages.issueSelect : SupportFlowPages.welcomeHub;
  const [currPage, setCurrPage] = useState({
    page: SupportFlowPages[initialPage] || newPage,
    props: {},
  });
  const [pageHistory, setPageHistory] = useState([]);

  // When initialPage changes reset history to this page
  useEffect(() => {
    const newPath = {
      page: SupportFlowPages[initialPage] || newPage,
      props: currPage.props,
    };
    setCurrPage(newPath);
    setPageHistory([]);
  }, [initialPage]);

  const goBack = () => {
    const newPage = pageHistory.pop();
    setCurrPage(newPage);
    setPageHistory(pageHistory);
  };

  const navigateTo = (page: SupportFlowPages, pageOptions?: { [key: string]: any }) => {
    const newPath = { page, props: pageOptions };
    const prevPath = currPage;
    setCurrPage(newPath);
    setPageHistory([...pageHistory, prevPath]);
  };

  const Component = supportPages[currPage.page];
  return (
    <SolvvyWindow
      containerHeight={containerHeight}
      onCloseClick={onClose}
      onBackClick={pageHistory.length ? goBack : firstStepBack}
      show={show}
      solvvyVersion={solvvyVersion}
    >
      <Component isMobileView navigateTo={navigateTo} onClose={onClose} {...currPage.props} />
    </SolvvyWindow>
  );
};

export default SupportFlow;
